import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell en forma cilíndrico-ovalada que esdevé acampanat, de 4-10 cm d’altura i de 5 a 8 cm de diàmetre. És de color marronós, amb estries radials ben marcades i coberta parcialment de grans plaques escamoses de color blanc que són fàcilment arrossegades per la pluja. Les làmines estan lliures, atapeïdes, al principi blanques, després rosades i finalment negres i deliqüescents. El peu és blanc, cilíndric, finament tomentós i de 10-25 x 1-2 cm. Les espores són negres en massa, ovalades, amb un porus germinatiu de 10,5-18 x 10-13 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      